
var UK = {
  "Blog"                   : "Блог",
  "Russian"                : "Русский",
  "Connect"                : "Підключитися",
  "English"                : "English",
  "Ukrainian"              : "Українська",
  "Developers"             : "Розробникам",
  "Opportunities"          : "Можливості",
  "About the company"      : "Про компанію",
  "About"                  : "Про компанію",
  "How to start?"          : "Як почати?",
  "How to connect to API?" : "Як підключитися до API?",
  "API checkout?"          : "API checkout?",
  "Payment API"            : "Платіжне API",
  "Test details"           : "Тестові реквізити",
  "API аnswers"            : "Відповіді API",
  "Bank response codes"    : "Коди відповідей банку",
  "Security measures"      : "Заходи безпеки",
  "Description of statuses": "Опис статусів",
  "Send request"           : "Відправити запит",
  "Managers"               : "Запитати менеджера",
  "Documentation"          : "Документація",
  "Registration"           : "Реєстрація",
  "Language"               : "Мова",


  //----------------------Home----------------------//

  "Online "                                                                                                                                                            : "Система ",
  "payment system "                                                                                                                                                    : "онлайн-платежів ",
  "for business"                                                                                                                                                       : "для бізнесу",
  "We have collected the most popular tools for online business."                                                                                                      : "Ми зібрали найбільш популярні інструменти для ведення онлайн бізнесу.",
  "More features"                                                                                                                                                      : "Більше можливостей",
  "from Finkit"                                                                                                                                                      : "з Finkit",
  "Convenient payments acceptance"                                                                                                                                     : "Зручний прийом платежів",
  "Popular payment methods for your site in one service: Visa, Mastercard and Prostir cards of any bank."                                                              : "Популярні способи оплати для вашого сайту в одному сервісі: картами Visa, Mastercard і Prostir будь-якого банку.",
  "Personal account"                                                                                                                                                   : "Особистий кабінет",
  "Access to payments and statistics 24/7. Manage payments. Control payments, return payments to customers, generate reports, configure filtering and data processing.": "Доступ до платежів і статистики 24/7. Керуйте платежами. Контролюйте платежі, повертайте оплату клієнтам, формуйте звіти, налаштовуйте фільтрацію та обробку даних.",

  "Easy to connect "                                                                                                                                                                  : "Легко підключитися ",
  "and get started"                                                                                                                                                                   : "і почати роботу",
  "Simple API"                                                                                                                                                                        : "Доступне API",
  "Our APIs allow you to attract customers and pay for goods or services with innovative payment solutions."                                                                          : "Наші АРІ-інтерфейси дозволяють залучати клієнтів і оплачувати товари або послуги за допомогою інноваційних платіжних рішень.",
  "We care about your safety"                                                                                                                                                         : "Ми дбаємо про вашу безпеку",
  "We follow the instructions of the payment systems Prostir, Visa, Mastercard and take additional measures to protect the information of Internet entrepreneurs and their customers.": "Ми виконуємо приписи платіжних систем Простір, Visa, Mastercard та вживаємо додаткових заходів щодо захисту інформації Інтернет підприємців та їх клієнтів.",
  "We are certified according to the international security standard PCI DSS and follow the instructions of Visa and MasterCard payment systems for information security and processing of payment card data during payments and transfers.": "Ми сертифіковані за міжнародним стандартом безпеки PCI DSS та виконуємо приписи платіжних систем Visa та MasterCard щодо інформаційної безпеки та обробки даних платіжних карток під час здійснення платежів та переказів.",
  "Open and user-friendly API "                                                                                                                 : "Відкрите і зручне API ",
  "Simple integration"                                                                                                                          : "Проста інтеграція",
  "We try to find appropriate solutions so that your teams do not have to spend too much time implementing payment functions for your business.": "Ми стараємося знайти доречні рішення, щоб вашим командам не доводилося витрачати зайвий час на впровадження платіжних функцій для вашого бізнесу.",

  "We guarantee safety"                                                                                    : "Гарантуємо безпеку",
  "3D Secure"                                                                                              : "3D Secure",
  "and SecureCode"                                                                                         : "і SecureCode",
  "SSL"                                                                                                    : "SSL",
  "certificates"                                                                                           : "сертифікати",
  "Protocol"                                                                                               : "Протокол",
  "HTTPS"                                                                                                  : "HTTPS",
  "Verified"                                                                                               : "Verified",
  "by Visa"                                                                                                : "by Visa",
  "MasterCard"                                                                                             : "MasterCard",
  "SecureCode"                                                                                             : "SecureCode",
  "PCI DSS"                                                                                                : "Сертифіковані ",
  "requirements"                                                                                           : "згідно PCI DSS",
  "Tariffs"                                                                                                : "Тарифи",
  "Turnover of your business"                                                                              : "Оборот вашого бізнесу",
  "up to UAH 500,000 / month"                                                                              : "до 500 000 грн в місяць",
  "from the received payment"                                                                              : "від отриманого платежу",
  "from UAH 500,000 / month"                                                                               : "від 500 000 грн в місяць",
  "more than UAH 5,000,000 / month"                                                                        : "більше 5 000 000 грн в місяць",
  "Individual "                                                                                            : "Індивідуальний ",
  "tariff"                                                                                                 : "тариф",
  "We are available 24/7"                                                                                  : "Ми доступні",
  "Questions, difficulties or doubts? Write to us, the customer support service will contact you."         : "Виникли питання, труднощі або сумніви? Напишіть нам, служба підтримки майбутніх клієнтів зв'яжеться з вами.",
  "Ready to get started?"                                                                                  : "Готові розпочати?",
  "Fill out the form and our managers will contact you to increase the individual offer for your business.": "Заповніть форму і наші менеджери зв'яжуться з вами, щоб розробити індивідуальну пропозицію для вашого бізнесу.",
  "Certified according to standards"                                                                       : "Сертифіковані згідно стандартів",


  //----------------------Opportunities----------------------//

  "The most popular"                                                                                                                                             : "Найпопулярніші",
  "payment methods"                                                                                                                                              : "способи оплати",
  "in one service"                                                                                                                                               : "в одному сервісі",
  "With the help of the Finkit system, you can accept payments with cards of the international payment systems Visa and Mastercard and national cards Prostir.": "За допомогою системи Finkit можна приймати оплати картами міжнародних платіжних систем Visa і Mastercard і національними картками «Простір».",
  "Organizing the choice of a convenient method of payment for the customer increases the share of successful purchases and loyalty to your brand."              : "Організація вибору зручного способу оплати для клієнта підвищує частку успішних покупок і лояльність до вашого бренду.",

  "Comfortable and functional"                                                                                                                                                             : "Зручний і функціональний",
  "personal account"                                                                                                                                                                       : "особистий кабінет",
  "Thanks to the functions of the personal account, you will not miss the received payment, and you will always have statistics and reports on the current state of your business at hand.": "Завдяки функціям особистого кабінету ви не пропустите отриманий платіж, а також будете завжди тримати під рукою статистику і звіти про поточний стан вашого бізнесу",
  "Control the return of payments"                                                                                                                                                         : "Контролюйте повернення",
  "Make a full or partial refund of the payment on the customer's card in a couple of clicks"                                                                                              : "Робіть повне або часткове повернення суми платежу на карту клієнта в пару кліків.",

  "How it works?": "Як це працює?",
  //адаптировать блок с цифрами
  "To get started, you need to register and access your personal account."                                          : "Щоб почати роботу, вам потрібно зареєструватися та отримати доступ до особистого кабінету.",
  "To authenticate records in the API, you need a secret key from the personal account."                            : "Для аутентифікації запитів в API вам буде потрібно секретний ключ з особистого кабінету.",
  "Insert code"                                                                                                     : "Вставка коду",
  "Copy the code to receive the payment, replacing it on the test page with a secret key from the personal account.": "Скопіюйте код для прийому платежів замінивши в ньому тестові дані на секретний ключ з особистого кабінету.",
  "Paste the code on your site."                                                                                    : "Вставте код на ваш сайт.",
  "Done!"                                                                                                           : "Готово",
  "Hooray! You can accept online payments."                                                                         : "Ура! Ви можете приймати онлайн платежі.",
  "All payment data, statuses and detailed information will be available in the personal account."                  : "Всі дані про оплати, статусах і детальна інформація буде доступна в особистому кабінеті.",
  
  "You can easily accept payments"                                                                : "Ви можете легко приймати платежі",
  "To do this, simply register and access the personal account."                                  : "Для цього достатньо зареєструватися і отримати доступ до особистого кабінету.",
  "Other possible integration scenarios"                                                          : "Інші можливі сценарії інтеграції",
  "If the described scenario does not fit your task, you can choose another integration scenario.": "Якщо описаний сценарій не підходить під вашу задачу, ви можете вибрати інший сценарій інтеграції.",

  "Customized payment page"                                                                                                                                                                             : "Кастомізована сторінка оплати",
  "Branded payment page"                                                                                                                                                                                : "Брендована сторінка оплати",
  "The unique design and style of your resource and payment service increases the conversion and customer loyalty. Customer confidence in the payment process ensures the development of your business.": "Єдине оформлення і стилістика вашого ресурсу і платіжного сервису підвищує конверсію і лояльність клієнтів. Довіра клієнтів до процесу оплати забезпечує розвиток вашого бізнесу.",
  "Adaptability"                                                                                                                                                                                        : "Адаптивність",
  "The payment page will display correctly and correctly in all browsers and platforms, so users will find it convenient to make a purchase in most situations and on most devices."                    : "Організація вибору зручного способу оплати для клієнта підвищує частку успішних покупок і лояльність до вашого бренду.",
  "You can configure everything"                                                                                                                                                                        : "Налаштувати можна все",
  "You can customize the color, background, design of the fields and buttons in just a couple of clicks."                                                                                               : "Можна налаштувати колір, фон, оформлення полів і кнопок всього в пару кліків.",
  "Thanks to the component approach, Finkit gives complete freedom of action to customize the payment page."                                                                                          : "Завдяки компонентному підходу Finkit дає повну свободу дій по кастомізації платіжної сторінки.",

  //----------------------Developers----------------------//
  "Allows you to accept payments, make returns, find out the status of transactions and much more."                                                    : "Дозволяє приймати платежі, робити повернення, дізнаватися про статус операцій і багато іншого.",
  "The documentation contains descriptions of the main scenarios with code examples. It only takes a few clicks and lines of code to accept a payment.": "В документації зібрані описи основних сценаріїв з прикладами коду. Щоб прийняти платіж, потрібно всього декілька кліків та рядків коду.",
  "The most useful"                                                                                                                                    : "Найкорисніше",


  //----------------------About----------------------// 
  "Finkit is a service built by specialists in the field of finance and IT systems in the field of electronic payments."                                    : "Finkit - сервіс, що будується фахівцями в галузі фінансів та IT-систем в галузі електронних розрахунків.",
  "We create and maintain financial services for people and businesses."                                                                                      : "Ми створюємо і підтримуємо фінансові сервіси для людей і бізнесу.",
  "We search, combine and implement the most modern solutions for convenient and secure payment, obtaining information for the growth of customers' business.": "Ми шукаємо, комбінуємо та впроваджуємо найсучасніші рішення для зручного та безпечного обігу платежів, отримання інформації для зростання бізнесу клієнтів.",
  "Scale business with us"                                                                                                                                    : "Масштабуйте бізнес з нами",
  "Just create an account and start accepting payments. You can also contact us to develop an individual package for your business."                          : "Просто створіть обліковий запис і почніть приймати платежі. Ви також можете зв'язатися з нами, щоб розробити індивідуальний пакет для вашого бізнесу.",
  "We are always in touch"                                                                                                                                    : "Ми завжди на зв’язку",
  "Working hours: around the clock"                                                                                                                           : "Графік роботи: цілодобово",
  "We will answer your questions:"                                                                                                                            : "Відповімо на ваші запитання:",
  "Legal name"                                                                                                                                               : "Юридичне найменування",
  "LIMITED LIABILITY COMPANY \"FINKIT\""                                                                                                                      : "Товариство з обмеженою відповідальністю \"ФІНКІТ\"",
  "Legal address (place of registration on the territory of Ukraine)"                                                                                        : "Юридична адреса (місце реєстрації на території України)",
  "Ukraine, 01042, Kyiv city, Ioanna Pavla II street, building 4/6, building B, room 507/3"                                                                   : "Україна, 01042, місто Київ, вул.Іоанна Павла II, будинок 4/6, корпус В, кабінет 507/3",
  "Contact Information"                                                                                                                                       : "Контактна інформація",
  "Phone"                                                                                                                                                    : "Телефон",
  "Email"                                                                                                                                                    : "Пошта",




  
  //----------------------ConnectForm----------------------// 
  "Connection request"                                                                                                : "Запит на підключення",
  "We are processing your connection request and our manager will contact you."                                       : "Ми опрацюємо ваш запит на підключення і наш менеджер зв’яжеться з вами.",
  "Finkit features"                                                                                                   : "Можливості Finkit",
  "Convenient payment acceptance"                                                                                     : "Зручний прийом платежів",
  "Our API interfaces allow you to attract customers and pay for goods or services with innovative payment solutions.": "Наші АРІ-інтерфейси дозволяють залучати клієнтів і оплачувати товари або послуги за допомогою інноваційних платіжних рішень.",
  "Indicate the approximate turnover to be strengthened."                                                             : "Вкажіть приблизний оборот коштів щомісяця.",
  "Tell us more about your project and needs"                                                                         : "Розкажіть нам більше про свій проект і потреби",
  "Tell us more about your project or questions"                                                                      : "Введіть повідомлення або запитання",
  "Payments scope"                                                                                                    : "Обсяг платежів",
  "Website"                                                                                                           : "Вебсайт",
  "Mobile phone"                                                                                                      : "Телефон",
  "Last name"                                                                                                         : "Прізвище",                                                                                           
  "First name"                                                                                                        : "Ім'я",
  "Up to 500 000 UAH/month"                                                                                           : "До 500 000 гривень/місяць",
  "From 500 000 UAH/month"                                                                                            : "Від 500 000 гривень/місяць",                                                                                           
  "Over 1 000 000 UAH/month"                                                                                          : "Понад 1 000 000 гривень/місяць",
  "From 0 to 50 000 UAH per month"                                                                                    : "від 0 до 50 000 гривень на місяць",
  "From 50 000 to 100 000 UAH per month"                                                                              : "від 50 000 до 100 000 гривень на місяць",   
  "From 100 000 to 500 000 UAH per month"                                                                             : "від 100 000 до 500 000 гривень на місяць",
  "Over 500 000 UAH per month"                                                                                        : "більше 500 000 гривень на місяць",   

  "Thank you, the form has been sent, we will contact you soon."                                                      : "Дякуємо, форма відправлена, ми зв’яжемось з вами найближчим часом.",
  "Sorry, something went wrong. Please try again later or contact online support."                                    : "Вибачте, щось пішло не так. Спробуйте пізніше або зверніться до онлайн-підтримки.",
  "Required field. Minimum 3 characters"                                                                              : "Обов'язкове поле. Мінімум 3 символи",
  "Minimum 3 characters"                                                                                              : "Мінімум 3 символи",
  "The format of the email address is incorrect"                                                                      : "Невірний формат email-адреси",
  "Enter the phone number in the format: +380 (##) ###-##-##"                                                         : "Вкажіть номер телефону у форматі: +380 (##) ###-##-##",
  "Specify the site address in the format: example.com"                                                               : "Вкажіть адресу сайту у форматі: example.com",





  //----------------------managers----------------------// 
  "Ask the manager"                                                         : "Запитати менеджера",
  "Our team will be happy to answer your questions."                        : "Наша команда з радістю відповість на ваші запитання.",
  "Fill out the form and we will contact you shortly."                      : "Заповніть форму, і ми зв'яжемося з вами найближчим часом.",
  "Our team will be happy to answer your questions. Fill out the form and we will contact you shortly." : "Наша команда з радістю відповість на ваші запитання. Заповніть форму, і ми зв'яжемося з вами найближчим часом.",
  "For general questions, including partnership opportunities, please email": "Із загальними питаннями, включаючи можливості партнерства, звертайтеся по електронній пошті ",
  "General questions"                                                       : "Загальні питання",
  "For general questions, including partnership opportunities"              : "Із загальними питаннями, включаючи можливості партнерства,",
  "please email"                                                            : "звертайтеся по електронній пошті",

  //----------------------openinfo----------------------//
  "Disclosure of information"                                               : "Розкриття інформації",
  "View"                                                                    : "Переглянути",
  "Our company is committed to transparency and openness in communication with all stakeholders." : "Наша компанія віддана прозорості та відкритості в спілкуванні зі всіма зацікавленими сторонами.",
  "This section provides an opportunity to thoroughly acquaint oneself with the information reflecting the company's activities.": "Цей розділ надає можливість детально ознайомитися з інформацією, яка відображає діяльність компанії.",


  //--------------------- Support Button ---------------//
  "Need help?" : "Потрібна допомога?",
  "We are always here" : "Ми завжди на зв’язку",
  
};

export default UK;
