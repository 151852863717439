import React from 'react';

import './Image.css';

class Image extends React.Component{

  constructor( props ){
    super(props);
    var state      = this._initState( this.props );
        this.state = state;
  }
  
  _initState = ( props ) => {
    var state = {
      "name"  : "",
      "type"  : "",
      "style" : "",
      "source": "",
    };

    if( props !== undefined ){
      for( var key in props ){
        state[key] = props[key];
      }
    }
    return state;
  }

  render(){

    return(
      <div className = {"Image " + this.state.style + " " + this.state.type} onClick = { this.props.handler }>
      <div className = {"Image-Source " + this.state.style + " " + this.state.type + " " + this.state.source}/>
      </div>
    )
  }

}

export default Image;

