import React from 'react';
import Label  from '../Components/Label/Label';
import {connect} from 'react-redux'
import ManagerForm from '../Components/Form/ManagerForm';



class Managers extends React.Component{
  
  constructor( props ){
    super(props);
    var state  = this._initState( this.props );
    this.state = state;
  }

  _initState = ( props ) => {

    var state = {
      "name"    : "",
      "type"    : "",
      "text"    : "",
      "style"   : "",
      "title"   : "",
      "children": []
    };

    if( props !== undefined ){
      for( var key in props ){
        state[key] = props[key];
      }
    }
    return state;
  } 
  
  render() { 
    return(
      <div className="App-Body-Content">
        <div className="Title" name="pb24">
          <Label type="H1" text="Ask the manager" name="splited" />
        </div>
        <div className="App-Row pb24 short" name="pb24">
          <Label type="H320" text="Our team will be happy to answer your questions. Fill out the form and we will contact you shortly." name="splited" />
        </div>
        <div className="Form-Row">
          <div className="Form-Col rp8">
            <ManagerForm
              language = {this.props.language}
            />
          </div>            
          <div className="Form-Col lp8" name="last">
            <div className="Form-pad14">
              <Label type="H3F" text="General questions" name="splited" />
            </div>
            <div className="Form-pad14" name="pb24">
              <Label type="H320" text="For general questions, including partnership opportunities, please email" />
            </div>

            <div className="Form-pad14" name="pb24">
              <Label type="Mail H4G" textField="mail" text="info@finkit.com.ua" name="splited" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return{
    language: state.page_state.data.language,
  }
}

export default connect(mapStateToProps)(Managers);