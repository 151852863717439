import React, { useState, useEffect } from 'react';
import Label      from '../Label/Label';
import axios      from 'axios';                     
import classnames from 'classnames';  
import InputMask  from "react-input-mask";          
import chevron    from './img/chevron.png';          
import chevron_up from './img/chevron_up.png';  
import Translate  from '../../Utils/Transalte';


function ConnectForm(propsData) {

  const language = propsData.language;

  
  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [email, setemail] = useState('');
  const [phone, setphone] = useState('');
  const [website, setwebsite] = useState('');


  const [firstNameErr, setfirstNameErr] = useState('');
  const [lastNameErr, setlastNameErr] = useState('');
  const [emailErr, setemailErr] = useState('');
  const [phoneErr, setphoneErr] = useState('');
  const [websiteErr, setwebsiteErr] = useState('');

  
  const initialFormState = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    website: '',
    scope:'',
    description: '',         
  };

  const [formState, setFormState] = useState(initialFormState);
  const [phoneFocused, setPhoneFocused] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [description, setdescription] = useState();


  useEffect(() => {
    const isValid = !phoneFocused && firstName && email && firstNameErr === '' &&  emailErr === '' && phoneErr === '' && lastNameErr === '' && websiteErr === '';
    setCanSubmit(isValid);
  }, [firstName, phoneFocused, email, phone, firstNameErr, lastNameErr, websiteErr, emailErr, phoneErr]);


  const submitForm = async (event) => {
    event.preventDefault();
    if(canSubmit && !phoneFocused){
      setSubmitting(true);
      await postSubmission();
      setSubmitting(false);
    }
  };
   
  const postSubmission = async() => {

    const data = {
      ...formState,
    };


    const payload = {
      support_type: 'connect',
      support_channel: 'landing',
      support_information: data,
      support_status: 'created',
      support_send_mail: process.env.REACT_APP_SUPPORT_SEND_MAIL
    }

    try {
      var submitURL = process.env.REACT_APP_SUBMIT_URL;

      await axios.post(submitURL, payload);
      // console.log(result);
      setdescription({
        text: Translate("Thank you, the form has been sent, we will contact you soon.", language),
      });
    } catch(error) {
      console.log(error);
      setdescription({
        text: Translate("Sorry, something went wrong. Please try again later or contact online support.", language),
      });
    } 
  };



  const updateFormControl = (event, name) => {
    const { id, value } = event.target;
    const formKey = id;
    const updateFormState = {...formState};
    updateFormState[formKey] = value;
    setFormState(updateFormState);
        
    const user = {};
    const emailRegEx = RegExp(              	
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    const phoneRegEx = RegExp(
        /((\+380)?\(?\d{2}\)?[\s.-]?(\d{7}|\d{3}[\s.-]\d{2}[\s.-]\d{2}|\d{3}-\d{4}))/
    );
    const websiteRegEx = RegExp(
        /^((?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9-%/&=?.]+\.[a-z]{2,4}\/?([^\s<>#%",{}\\|\\^[\]`]+)?)/
    );
    user[name] = event.currentTarget.value;

    switch (name) {
      case 'firstName': 
        setfirstName(user.firstName);
          user.firstName.length < 3 ? setfirstNameErr("Required field. Minimum 3 characters") : setfirstNameErr('');
        break;
          
      case 'lastName': 
        setlastName(user.lastName);
          (user.lastName.length < 3 && user.lastName !== '') ? setlastNameErr("Minimum 3 characters") : setlastNameErr('');
        break;
      case 'email': 
        setemail(user.email);
          !emailRegEx.test(user.email) ? setemailErr("The format of the email address is incorrect") : setemailErr('');
        break;
      case 'phone': 
        setphone(user.phone);
          (!phoneRegEx.test(user.phone) && user.phone !== '') ? setphoneErr("Enter the phone number in the format: +380 (##) ###-##-##") : setphoneErr('');
        break;
      case 'website': 
        setwebsite(user.website);
          (!websiteRegEx.test(user.website) && user.website !== '') ? setwebsiteErr("Specify the site address in the format: example.com") : setwebsiteErr('');
        break;
      default:
        break;
    };
    
}; 

  const [selected,setSelected] = useState("Payments scope");    
  const [isActive, setIsActive] = useState(false);
  const [datas, setDatas] = useState('1')
  const [fontstyle,setFontstyle] = useState('dropdown-font')

  const options = [
    "From 0 to 50 000 UAH per month", 
    "From 50 000 to 100 000 UAH per month",
    "From 100 000 to 500 000 UAH per month",
    "Over 500 000 UAH per month"
  ];   
  // const options = ["Up to 500 000 UAH/month" ,"From 500 000 UAH/month" ,"Over 500 000 UAH per month"];    
  const mix = (event,name) => {
    const id = 'scope';    
    const formKey = id;
    const upgradeFormState = {...formState}
    upgradeFormState[formKey] = datas;
    setFormState(upgradeFormState);
  }

  const Replaces = (event,name) => {
    const id = 'email';
    const id2 = 'phone';
    const id3 = 'website';
    const id4 = 'scope';
    const id5 = 'firstName';
    const id6 = 'lastName';

    const formKey = id;
    const formKey2 = id2;
    const formKey3 = id3;
    const formKey4 = id4;
    const formKey5 = id5;
    const formKey6 = id6;

    const phoneInputState = {...formState}
    phoneInputState[formKey] = email.toLowerCase().replace(/[^a-z\s,.,@]/gi, '');
    phoneInputState[formKey2] = phone.replace(/[^\d]/g, '');
    phoneInputState[formKey3] = website.toLowerCase().replace(/[^a-z\s,.]/gi, '');
    phoneInputState[formKey4] = datas;
    phoneInputState[formKey5] = firstName.replace(/[0-9]/g, "");
    phoneInputState[formKey6] = lastName.replace(/[0-9]/g, "");
    setFormState(phoneInputState);
  }

  return (
    <div className="Forms">
      <form onSubmit={submitForm}>
        <div className="Form-Div">
          <input
            className={classnames(
              "form-control",
              { 'is-invalid': emailErr, 'is-valid': !emailErr && email.length}
            )} 
            onChange={(event) => updateFormControl(event, 'email')}
              
            type="text" 
            id="email"
            name="email"  
            value={formState.email.toLowerCase().replace(/[^a-z\s,.,@]/gi, '')}
            placeholder="E-mail"
            noValidate>
          </input>
          <div className="error_field">
            {emailErr && <small className="text-danger">{Translate(emailErr, language)}</small>}
          </div>
        </div>
        <div className="Form-Div">
        <input 
          className={classnames(
            "form-control",
            { 'is-invalid': firstNameErr, 'is-valid': !firstNameErr && firstName.length}
          )}
          onChange={(event) => updateFormControl(event, 'firstName')}
          type="text"
          name="firstName" 
          id="firstName"
          value={formState.firstName.replace(/[0-9]/g, "")} 
          placeholder={Translate("First name" , language)}
          noValidate
          required>
        </input>
        <div className="error_field">
          {firstNameErr &&<small className="text-danger">{Translate(firstNameErr, language)}</small>}
        </div>
      </div>
      <div className="Form-Div">
        <input 
          className={classnames(
            "form-control",
              { 'is-invalid': lastNameErr, 'is-valid': !lastNameErr && lastName.length}
          )}            
          onChange={(event) => updateFormControl(event, 'lastName')}
          type="text"
          name="lastName"  
          id="lastName" 
          value={formState.lastName.replace(/[0-9]/g, "")}
          placeholder={Translate("Last name" , language)}
          noValidate>
        </input>
        <div className="error_field">
          {lastNameErr &&<small className="text-danger">{Translate(lastNameErr, language)}</small>}
        </div>   
      </div>
        <div className="Form-Div">
          <InputMask
              className={classnames(
                "form-control",
                { 'is-invalid': phoneErr, 'is-valid': !phoneErr && phone.length}
              )}
              placeholder={Translate("Mobile phone" , language)}
              mask="+380 (99) 999-99-99"
              onChange={(event) => updateFormControl(event, 'phone')} 
              onFocus={() => setPhoneFocused(true)}
              onBlur={() => setTimeout(() => {
                setPhoneFocused(false);
              }, 200)}
              type="tel" 
              id="phone" 
              name="phone"
              inputMode="numeric"
              value={formState.phone}
              noValidate
            />
          <div className="error_field">
              {phoneErr && <small className="text-danger">{Translate(phoneErr, language)}</small>}
          </div> 
        </div>
        <div className="Form-Div">
          <input
              className={classnames(
                "form-control",
                { 'is-invalid': websiteErr, 'is-valid': !websiteErr && website.length}
              )}             
              onChange={(event) => updateFormControl(event, 'website')}
              type="text"
              id="website"
              name="website" 
              value={formState.website.toLowerCase().replace(/[^a-z\s,.]/gi, '')}
              placeholder={Translate("Website", language)}
              noValidate> 
          </input>
          <div className="error_field">
            {websiteErr && <small className="text-danger">{Translate(websiteErr, language)}</small>}
          </div>
        </div>
        <div className="dropdown">
          <div className="dropdown-btn" onClick={(event) => {setIsActive(!isActive)}}>
            <div className={fontstyle}>{Translate(selected, language)}</div>
              <span >
              {isActive ? 
                <img src={chevron_up} alt="Logo" className="chevron"/> 
              : 
                <img src={chevron} alt="Logo" className="chevron"/>
              } 
              </span>                 
          </div>
            {isActive && (
              <div className="dropdown-content">
                {options.map(options =>(
                  <div
                  name='test'
                  onClick={(event) => { 
                  setSelected(options)
                  setIsActive(false)
                  setDatas(options)
                  setFontstyle('dropdown-font2')
                }}
                    className="dropdown-item">{Translate(options, language)}
                  </div>
                ))}
              </div>           
            )}                  
        </div>
        <div className="position">
          <Label type="Form_text" text="Indicate the approximate turnover to be strengthened." name="scope" id="scope" value={selected} onMouseOver={updateFormControl} />
        </div>    
        <div>
          <textarea
            className="form-control textarea"
            height="119px"
            type="text" 
            id="description"
            name="description" 
            value={formState.description}
            onChange={updateFormControl}
            onClick={Replaces}
            placeholder={Translate("Tell us more about your project and needs", language)}>
          </textarea>
        </div>
          {description && (
            <div className="FormSend">
              <div className="TextSend">
                {description.text}
              </div>
            </div>
          )}
        <div onClick={mix}>
          <button 
            className={"btn btn_primary request " + (firstName && email && firstNameErr === '' &&  emailErr === '' && phoneErr === '' && lastNameErr === '' && websiteErr === '' ? "active" : "disabled")}  
            disabled={!canSubmit} 
            t={submitting}>
            {Translate("Send request", language)}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ConnectForm;
