import React from 'react';
import Label from '../Components/Label/Label';
import Image from '../Components/Image/Image';
import Button from '../Components/Button/Button';

class Home extends React.Component {

  renderHeaderSection() {
    const headerLabels = ["Online ", "payment system ", "for business"];
    return (
      <div className="App-Row pb160">
        <div className="App-Col rp8">
          <div className="App-Row" name="pb24">
            {headerLabels.map((text, index) => <Label key={index} type="H1" text={text} name="splited" />)}
          </div>
          <div className="App-Row">
            <Label type="H4" text="We have collected the most popular tools for online business." />
          </div>
          <div className="App-Row">
            <Button text="Connect" type="Header-Navigation-Button" action="state" value={{"page":"connect"}}/>
          </div>
        </div>
        <div className="App-Col lp8" name="last">
          <Image type="Big-Cards"/>
        </div>
      </div>
    );
  }

  renderFeaturesSection() {

    return (
      <div className="App-Row pb160">
        <div className="App-Col rp8">
          <div className="App-Row">
            <Label type="H2" text="More features" />
            <Label type="H2" text="from Finkit" />
          </div>
          <div className="App-Row" name="pb8">
            <Label type="H3" text="Convenient payments acceptance" />
          </div>
          <div className="App-Row">
            <Label type="P" text="Popular payment methods for your site in one service: Visa, Mastercard and Prostir cards of any bank." />
          </div>
          <div className="App-Row" name="pb8">
            <Label type="H3" text="Personal account" />
          </div>
          <div className="App-Row">
            <Label type="P" text="Access to payments and statistics 24/7. Manage payments. Control payments, return payments to customers, generate reports, configure filtering and data processing." />
          </div>
        </div>
        <div className="App-Col lp8">
          <Image type="Lot-Of-Staff"/>
        </div>
      </div>
    );
  }

  renderAPIIntegrationSection() {
    return (
      <div className="App-Row pb160">
        <div className="App-Col">
          <div className="App-Row" name="pb24">
            <Label type="H2" text="Open and user-friendly API " />
            <Label type="H2" text="Simple integration" />
          </div>
          <div className="App-Row">
            <Label type="P" text="We try to find appropriate solutions so that your teams do not have to spend too much time implementing payment functions for your business." />
          </div>
          <div className="App-Row">
            <Button text="Documentation" type="Header-Navigation-Button" action="state" value={{"page":"developers"}}/>
          </div>
        </div>
        <div className="App-Col">
          <Image type="API"/>
        </div>
      </div>
    );
  }

  renderSafetyGuaranteesSection() {
    const safetyFeatures = [
      { imageType: "Big-Icon ThreeDS", title: "3D Secure", secondTitle: "and SecureCode" },
      { imageType: "Big-Icon SSL", title: "SSL", secondTitle: "certificates" },
      { imageType: "Big-Icon HTTPS", title: "Protocol", secondTitle: "HTTPS" },
      { imageType: "Big-Icon Visa", title: "Verified", secondTitle: "by Visa" },
      { imageType: "Big-Icon MasterCard", title: "MasterCard", secondTitle: "SecureCode" },
      { imageType: "Big-Icon PCI-DSS", title: "PCI DSS", secondTitle: "requirements" },
    ];

    return (
      <div className="App-Row pb90">
        <div className="App-Row pb64">
          <div className="App-Col">
            <Label type="H2" text="We guarantee safety" />
          </div>
        </div>
        <div className="App-Row">
          {safetyFeatures.map((feature, index) => (
            <div key={index} className="App-Col w33 inline pb64">
              <Image type={feature.imageType}/>
              <Label type="H3" text={feature.title} />
              <Label type="H3" text={feature.secondTitle} />
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderTariffsSection() {
    const tariffs = [
      {
        title: "Turnover of your business",
        secondTitle: "up to UAH 500,000 / month",
        rate: "2.7%",
        description: "from the received payment"
      },
      {
        title: "Turnover of your business",
        secondTitle: "from UAH 500,000 / month",
        rate: "2.5%",
        description: "from the received payment"
      },
      {
        title: "Turnover of your business",
        secondTitle: "more than UAH 5,000,000 / month",
        rate: ["Individual ", "tariff"],
        description: " "
      }
    ];
  
    return (
      <div className="App-Row pb160">
        <div className="App-Row pb64">
          <div className="App-Col">
            <Label type="H2" text="Tariffs" />
          </div>
        </div>
        <div className="container">
          {tariffs.map((tariff, index) => (
            <div key={index} className="col-4">
              <div className="Tariffs-Blocks">
                <div className="Tariffs-Col mgn40">
                  <Label type="Label-Text P" text={tariff.title} />
                  <Label type="Label-Text P" text={tariff.secondTitle} />
                </div>
                <div className="Tariffs-Col mg8">
                  {/* <Label type="H1" text={tariff.rate} /> */}
                  {/* {tariff.rate.map((rate) => (
                    <Label type="Label H1 t3h1 p24" text={rate} />
                  ))} */}
                  {typeof tariff.rate === "string" ?  
                  <Label type="H1" text={tariff.rate} /> 
                  : 
                  tariff.rate.map((rate) => (
                    <Label type="Label H1 t3h1 p24" text={rate} />
                  ))}
                </div>
                <div className="Tariffs-Col1s mg64">
                  <Label type="H0" text={tariff.description} />
                </div>
                <div className="App-Col Rate">
                  <Button text="Connect" type="Navigation-Button-Page-Invert" action="state" value={{"page":"connect"}}/>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
  

  renderContactSection() {
    return (
      <div className="App-Row pb160">
        <div className="App-Row pb64">
          <Label type="H2" text="We are available 24/7" />
        </div>
        <div className="App-Row">
          <div className="App-Col inline">
            <Label type="P" text="Questions, difficulties or doubts? Write to us, the customer support service will contact you." />
          </div>
          <div className="App-Col inline">
            <Label type="Mail H3 Center Green" textField="mail" text="support@finkit.com.ua" />
          </div>
        </div>
      </div>
    );
  }

  renderStartSection() {
    return (
      <div className="App-Row p64-80 br60 BG-pw">
        <div className="App-Row pb24">
          <Label type="H2" text="Ready to get started?" />
        </div>
        <div className="App-Row Manager-Question">
          <div className="App-Col">
            <Label type="P" text="Fill out the form and our managers will contact you to increase the individual offer for your business."/>
          </div>
          <div className="App-Col Manager-Question">
            <div className="App-Col Manager">
              <Button type="Navigation-Button-Page-Invert" text="Managers" action="state" value={{"page":"managers"}} />
            </div>
            <div className="App-Col Connect">
              <Button type="Navigation-Button-Page" text="Connect" action="state" value={{"page":"connect"}} />           
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="App-Body-Content">
        {this.renderHeaderSection()}
        {this.renderFeaturesSection()}
        {this.renderAPIIntegrationSection()}
        {this.renderSafetyGuaranteesSection()}
        {this.renderTariffsSection()}
        {this.renderContactSection()}
        {this.renderStartSection()}
      </div>
    );
  }
}

export default Home;
