import React from 'react';
import {connect} from 'react-redux'
import Label  from '../Components/Label/Label';
import ConnectForm from '../Components/Form/ConnectForm';


class Connect extends React.Component{
  
  constructor( props ){
    super(props);
    var state  = this._initState( this.props );
    this.state = state;
  }
  
  _initState = ( props ) => {

    var state = {
      "name"    : "",
      "type"    : "",
      "text"    : "",
      "style"   : "",
      "title"   : "",
      "children": []
    };

    if( props !== undefined ){
      for( var key in props ){
        state[key] = props[key];
      }
    }
    return state;
  } 

  render() { 
    
    return(
        <div className="App-Body-Content">
            <div className="Title" name="pb24">
              <Label type="H1" text="Connection request" name="splited" />
            </div>
            <div className="App-Row pb24 short" name="pb24">
              <Label type="P" text="We are processing your connection request and our manager will contact you." name="splited" />
            </div>
        <div className="Form-Row">
          <div className="Form-Col rp8c">
            <ConnectForm
              language = {this.props.language}
             />
          </div>            
          <div className="Form-Col lp8c" name="last">
            <div className="Form-pad">
              <Label type="H3F" text="Finkit features" name="splited" />
            </div>
            <div className="Form-pad14" name="pb24">
              <Label type="H4G" text="Convenient payment acceptance" name="splited" />
              <Label type="H320" text="Popular payment methods for your site in one service: Visa, Mastercard and Prostir cards of any bank." />
            </div>
            <div className="Form-pad14" name="pb24">
              <Label type="H4G" text="Personal account" name="splited" />
              <Label type="H320" text="Access to payments and statistics 24/7. Manage payments. Control payments, return payments to customers, generate reports, configure filtering and data processing." />
            </div>
            <div className="Form-pad14" name="pb24">
              <Label type="H4G" text="Simple API" name="splited" />
              <Label type="H320" text="Our API interfaces allow you to attract customers and pay for goods or services with innovative payment solutions." />
            </div>
            <div className="Form-pad14" name="pb24">
              <Label type="H4G" text="We care about your safety" name="splited" />
              <Label type="H320" text="We are certified according to the international security standard PCI DSS and follow the instructions of Visa and MasterCard payment systems for information security and processing of payment card data during payments and transfers." />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return{
    language: state.page_state.data.language,
  }
}

export default connect(mapStateToProps)(Connect);