import React from 'react';
import Button from '../../Button/Button.js';
import { connect }  from 'react-redux';
import './LangForm.css';


class LangForm extends React.Component{

// Show/Hide classes
show = ( e ) => {
    e.target.parentNode.childNodes[1].classList.toggle("show-me");
    e.target.parentNode.childNodes[0].childNodes[1].classList.toggle("arrow_down");
    // e.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.childNodes[1].childNodes[1].classList.add("show-me");
}

hide = ( e ) => {
    e.target.parentNode.parentNode.parentNode.classList.remove("show-me");
    e.target.parentNode.parentNode.parentNode.parentNode.childNodes[0].childNodes[1].classList.remove("arrow_down");
}


render () {
    var lang = this.props.language;
    var curentLang = lang.toString();
    var langUa ='';
    // var langRu ='';
    var langEng ='';
    var action = this.show


        // if (curentLang === "ru") {
        //     langUa      = "App-Lang-V1";
        //     langRu      = "App-Lang-V2 show-me";
        //     langEng     = "App-Lang-V3";
        // };

        if (curentLang === "uk") {
            langUa      = "App-Lang-V1 show-me";
            langEng     = "App-Lang-V3";
        };

        if (curentLang !== "ru" && curentLang !== "uk") {
            langUa      = "App-Lang-V1";
            langEng     = "App-Lang-V3 show-me";
        };


return (
        <div className="App-Lang-Selector">
            <div className={langUa}>
                <div className="App-Curent-Lang show-me1"  onClick={action}>
                    <div className='App-CurentLang-Text'>Українська</div>
                    <div className='App-CurentLang-Img'></div>
                </div>
                <div className="App-Lang-Variants">
                    {/* <div className='App-Lang-item' onClick={this.hide} >
                        <Button text="Russian" type="App-Lang-Btn" action={"state"} value={{"language":"ru"}} ></Button>
                    </div> */}
                    <div className='App-Lang-item' onClick={this.hide} >
                        <Button text="English" type="App-Lang-Btn" action={"state"} value={{"language":"eng"}} ></Button>    
                    </div>  
                </div>
            </div>
            {/* <div className={langRu}>
                <div className="App-Curent-Lang show-me1" onClick={this.show}>
                    <div className='App-CurentLang-Text'>Русский</div>
                    <div className='App-CurentLang-Img'></div>                
                </div>
                <div className="App-Lang-Variants">
                    <div className="App-Lang-item" onClick={this.hide}>
                        <Button text="Ukrainian" type="App-Lang-Btn" action={"state"} value={{"language":"uk"}} ></Button>
                    </div>
                    <div className='App-Lang-item bottom' onClick={this.hide}>
                        <Button text="English" type="App-Lang-Btn" action={"state"} value={{"language":"eng"}} ></Button>
                    </div>
                </div>
            </div> */}
            <div className={langEng}>
                <div className="App-Curent-Lang show-me1" onClick={this.show}>
                    <div className='App-CurentLang-Text'>English</div>
                    <div className='App-CurentLang-Img'></div>  
                </div>
                <div className="App-Lang-Variants">
                    <div className='App-Lang-item'onClick={this.hide}>
                        <Button text="Українська" type="App-Lang-Btn" action={"state"} value={{"language":"uk"}} ></Button>
                    </div>
                    {/* <div className='App-Lang-item'onClick={this.hide}>
                        <Button text="Русский" type="App-Lang-Btn" action={"state"} value={{"language":"ru"}} ></Button>
                    </div> */}
                </div>
            </div>  
        </div>        
        );
    }
}
const mapStateToProps = (state) => {
    return{
      language:       state.page_state.data.language,
    }
  }
  
  export default connect(mapStateToProps)(LangForm);