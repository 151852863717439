import './App.css';

import './PageSkeleton/Pinned/Document-Pinned-Components.css'

// import Menu from './PageComponents/Menu/Menu';
import Body   from './PageSkeleton/Body/Body';
import Header from './PageSkeleton/Header/Header';

import SupportButton from './Components/SupportButton/SupportButton';

import { createStore, combineReducers } from 'redux'
import { Provider }                     from 'react-redux';

import PagesReducer                      from './Reducer/PagesReducer'



const AppReducers = combineReducers({
  page_state: PagesReducer
});

export const AppStore = createStore( AppReducers );

function App() {

  return (
    <div className="App">
      <Provider store={ AppStore }>
        <Header/>
        <Body/>
        <div className='document__pinned-component--right-bottom'>
            <SupportButton link="https://t.me/MyFinkitBot"/>
        </div>
      </Provider>
    </div>
  );
}

export default App;
