import UK from './langs/uk';

var langs = {
  "uk": UK
};

function Transalte( Text, Lang ){
  
  var Translation = Text;
  if( langs[Lang] !== undefined ){
    if( langs[Lang][Text] !== undefined ){
      Translation = langs[Lang][Text];
    }
  };

  return Translation;

};

export default Transalte;