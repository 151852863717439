import React from 'react';

import Label  from '../Components/Label/Label';
import Image  from '../Components/Image/Image';
import Button from '../Components/Button/Button';

class Connect extends React.Component{

  renderAboutInfo() {
    return(
      <div className="App-Row pb160">
        <div className="App-Col rp8">
          <div className="App-Row" name="pb24">
            <Label type="H1" text="About the company" name="splited" action="state"/>
          </div>
          <div className="App-Row">
            <Label type="P" text="Finkit is a service built by specialists in the field of finance and IT systems in the field of electronic payments." />
          </div>
          <div className="App-Row">
            <Label type="P" text="We create and maintain financial services for people and businesses." />
          </div>
          <div className="App-Row">
            <Label type="P" text="We search, combine and implement the most modern solutions for convenient and secure payment, obtaining information for the growth of customers' business." />
          </div>
        </div>
        <div className="App-Col lp8" name="last">
          <Image type="About"/>
        </div>
      </div>
    )
  }

  renderManagerForm() {
    return(
      <div className="App-Row pb160">
        <div className="App-Row p64-80 br60 BG-pw">
          <div className="App-Row pb24">
            <Label type="H2" text="Scale business with us" />
          </div>
          <div className="App-Row Manager-Question">
            <div className="App-Col" >
              <Label type="P" text="Just create an account and start accepting payments. You can also contact us to develop an individual package for your business."/>
            </div>
            <div className="App-Col Manager-Question" >
              <div className="App-Col Manager" >
                <Button type="Navigation-Button-Page-Invert" text="Managers" action="state" value={{"page":"managers"}} />
              </div>
              <div className="App-Col Connect" >
                <Button type="Navigation-Button-Page" text="Connect" action="state" value={{"page":"connect"}} />           
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderLegalInfo() {
    return(
      <div className="App-Row">
        <div className="App-Row rp8">
          <div className="App-Row pb24" name="pb24">
            <Label type="H4" text="Legal name" />
            <Label type="H3" text={"LIMITED LIABILITY COMPANY \"FINKIT\""} />
          </div>
          <div className="App-Row pb24" name="pb24">
            <Label type="H4" text="Legal address (place of registration on the territory of Ukraine)" />
            <Label type="H3" text="Ukraine, 01042, Kyiv city, Ioanna Pavla II street, building 4/6, building B, room 507/3" />
          </div>
        </div>

        <div className="App-Row rp8">
          
          <div className="App-Row pb24" name="pb24">
            <Label type="H4" text="Phone" />
            <Label type="Tel H3G" textField="tel" text="+38 (050) 867 78 34" />
          </div>
          <div className="App-Row pb24" name="pb24">
            <Label type="H4" text="Email" />
            <Label type="Mail H3G" textField="mail" text="info@finkit.com.ua" />
          </div>

          <div className="App-Row">
            <Label type="H3" text="Working hours: around the clock" />
          </div>
        </div>

        <div className="App-Col lp8">
          <Image type="Email"/>
        </div>
      </div>
    )
  }

  render() { 
    return(
      <div className="App-Body-Content">
        {this.renderAboutInfo()}
        {this.renderManagerForm()}
        {this.renderLegalInfo()}
      </div>
    )
  }
}

export default Connect;