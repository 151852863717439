import React, { useEffect, useState } from 'react';

import Burger from '../../Components/Burger/Burger';
import Button from '../../Components/Button/Button';

import './Header.css';

function Header() {
  
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <div className = {scroll ? "App-Header" : "App-Header1"}>
    <div className = "App-Header-Content">
        <Burger/>
        <Button type      = "Header-Logo-Finkit" action              = "state" value = {{"page":"home"}}/>
        <div    className = "App-Header-Navigation">
        <Button text      = "Opportunities" type             = "Header-Navigation-Link"   action = "state" value = {{"page":"opprtunities"}} ></Button>
        <Button text      = "Developers" type                = "Header-Navigation-Link"   action = "state" value = {{"page":"developers"}}></Button>
        <Button text      = "About" type                     = "Header-Navigation-Link"   action = "state" value = {{"page":"about"}} ></Button>
        <Button text      = "Disclosure of information" type = "Header-Navigation-Link"   action = "state" value = {{"page":"openinfo"}} ></Button>
        <Button text      = "Connect" type                   = "Header-Navigation-Button" action = "state" value = {{"page":"connect"}}></Button>
        </div>
      </div>
  </div>
  );
}

export default Header;
