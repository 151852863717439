//import classNames from 'classnames';
import React from 'react';
import Image  from '../../Components/Image/Image';
import Button from '../../Components/Button/Button';
import Label  from '../../Components/Label/Label';
import LangForm from '../../Components/Form/LangForm/LangForm';

import './Footer.css';

class Body extends React.Component{


  // Show/Hide classes
show = ( e ) => {
  e.target.parentNode.parentNode.childNodes[1].childNodes[0].childNodes[1].childNodes[0].childNodes[1].childNodes[0].childNodes[1].classList.remove("show-me");
  e.target.parentNode.parentNode.childNodes[1].childNodes[0].childNodes[1].childNodes[0].childNodes[1].childNodes[1].childNodes[1].classList.remove("show-me");
  e.target.parentNode.parentNode.childNodes[1].childNodes[0].childNodes[1].childNodes[0].childNodes[1].childNodes[2].childNodes[1].classList.remove("show-me");
  e.target.parentNode.childNodes[1].classList.remove("show-me");
  e.target.parentNode.childNodes[0].childNodes[1].childNodes[0].childNodes[1].childNodes[0].childNodes[0].childNodes[1].classList.remove("arrow_down");
  e.target.parentNode.childNodes[0].childNodes[1].childNodes[0].childNodes[1].childNodes[1].childNodes[0].childNodes[1].classList.remove("arrow_down");
  e.target.parentNode.childNodes[0].childNodes[1].childNodes[0].childNodes[1].childNodes[2].childNodes[0].childNodes[1].classList.remove("arrow_down");

}


  render() { 
    return(
      <div className="App-Footer">
        <div className="App-Footer-Content">
          <div className="Footer-Column-Left">
            <div className="Footer-Logo">
              <Button type="Footer-Logo-Finkit" action="state" value={{"page":"home"}}/>
            </div>
            <div className="Footer-Menu">
                <Button text="Opportunities" type="Footer-Navigation-Link small-part" action="state" value={{"page":"opprtunities"}} ></Button>
                <Button text="Developers" type="Footer-Navigation-Link small-part" action="state" value={{"page":"developers"}} ></Button>
                <Button text="About" type="Footer-Navigation-Link small-part" action="state" value={{"page":"about"}} ></Button>
                {/* <Button name="pre" text="Blog" type="Footer-Navigation-Link small-part1"></Button> */}
                <Button name="last" text="Disclosure of information" type="Footer-Navigation-Link small-part" action="state" value={{"page":"openinfo"}} ></Button> 
            </div>
            <div className="Footer-Icons">
              <div className="text">
                <Label type="P-White-Footer" text="Certified according to standards"></Label>
              </div>
              <div className="icons">
                <Image type="pci_dss_footer"/>
                <Image type="mc_footer"/>
                <Image type="visa_footer"/>
              </div>
            </div>
          </div>
          <div className="Footer-Column-Right">
            <div className="Footer-Language">
            <div>          
                <Label type="P-White" text="Language"></Label>
              </div>
              <LangForm/>
            </div>
          </div>
        </div>
        <div className='close_dropdown' onClick={this.show}></div>
      </div>

    )
  }
}

export default Body;
