import React from 'react';

import { connect }  from 'react-redux';

import Translate  from '../../Utils/Transalte';

import './FileButton.css';

class FileButton extends React.Component{

  constructor( props ){
    super(props);
    var state      = this._initState( this.props );
        this.state = state;
  }
  
  componentDidUpdate(){

  }

  _initState = ( props ) => {

    var state = {
      "name"      : "",
      "type"      : "",
      "text"      : "",
      "style"     : "",
      "title"     : "",
      "link"      : "",
      "children"  : []
    };


    if( props !== undefined ){
      for( var key in props ){
        state[key] = props[key];
      }
    }
    return state;
  }
  
  onClick = () => {
    const newWindow = window.open(this.props.link, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  }

  render(){
    return(
      <div name = {this.props.name} className = "File-Button">
        <div className="File-Button-Title-Wrapper">
          <div className = "File-Button-Icon"/>
          <div className = "File-Button-Text" onClick={this.onClick}>{this.props.title}</div>
        </div>
        <div className= "File-Button-View-Wrapper">
          <div className = "File-Button-Divider"/>
          <div className = "File-Button-Press-Wrapper" onClick={this.onClick}>
            <div className = "File-Button-Text-Show">{ Translate( this.state.text, this.props.language ) }</div>
            <div className = "File-Button-Icon-Arrow"/>
          </div> 
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  return{
    language: state.page_state.data.language,
  }
}

export default connect(mapStateToProps)(FileButton);

