import React from 'react';

import Label  from '../Components/Label/Label';
import Image  from '../Components/Image/Image';
import Button from '../Components/Button/Button';

import Numbers from '../Components/numbers';

class Opportunities extends React.Component{

  renderPaymentMethodSections() {
    return(
      <div className="App-Row pb160">
        <div className="App-Col rp8">
          <div className="App-Row" name="pb24">
            <Label type="H1" text="The most popular" name="splited" />
            <Label type="H1" text="payment methods" name="splited" />
            <Label type="H1" text="in one service" name="splited" />
          </div>
          <div className="App-Row">
            <Label type="P" text="With the help of the Finkit system, you can accept payments with cards of the international payment systems Visa and Mastercard and national cards Prostir." />
          </div>
          <div className="App-Row">
            <Label type="P" text="Organizing the choice of a convenient method of payment for the customer increases the share of successful purchases and loyalty to your brand." />
          </div>
        </div>
        <div className="App-Col lp8" name="last">
          <Image type="Box_With_Cards"/>
        </div>
      </div>
    )
  }

  renderPersonalAccountSections() {
    return(
      <div className="App-Row pb160">
        <div className="App-Col lp8" name="hidden">
          <Image type="Personal-Area"/>
        </div>
        <div className="App-Col rp8">
          <div className="App-Row">
            <Label type="H2" text="Comfortable and functional" />
            <Label type="H2" text="personal account" />
          </div>
          <div className="App-Row">
            <Label type="P" text="Thanks to the functions of the personal account, you will not miss the received payment, and you will always have statistics and reports on the current state of your business at hand." />
          </div>
          <div className="App-Row" name="pb8">
            <Label type="H3" text="Control the return of payments" />
          </div>
          <div className="App-Row">
            <Label type="P" text="Make a full or partial refund of the payment on the customer's card in a couple of clicks" />
          </div>
        </div>
      </div>
    )
  }

  renderNumbersSections() {
    return(
      <div>
        <div className="App-Row pb64">
            <Label type="Center H2" text="How it works?" />
        </div>
        <Numbers/>
      </div>
    )
  }

  renderButtonSections() {
    return(
      <div className="App-Cont m-pad">
        <div className="Cont-4">
          <div className="C-title cth">
            <Label type="H3" text="You can easily accept payments" /> 
          </div>
          <div className="C-text">
            <Label type="P" text="To do this, simply register and access the personal account." />
          </div>
          <div>
            <Button text="Connect" type="Header-Navigation-Button" action="state" value={{"page":"connect"}}/>
          </div>
        </div>

        <div className="Cont-4">
          <div className="C-title cth">
            <Label type="H3" text="Other possible integration scenarios" /> 
          </div>
          <div className="C-text cth2">
            <Label type="P" text="If the described scenario does not fit your task, you can choose another integration scenario." />
          </div>
          <div>
            <Button text="Managers" type="Navigation-Button-Page-Invert" action="state" value={{"page":"managers"}}/> 
          </div>
        </div>
      </div>
    )
  }

  renderCustomPageSections() {
    return(
      <div className="App-Row pb24">
        <div className="App-Row pb24">
          <Label type="H2" text="Customized payment page" />
        </div>
        <div className="App-Row pb24">
          <div className="App-Col" name="footer">
            <Image type="Custom-Page"/>
          </div>
          <div className="App-Col lp8" name="header">
            <div className="App-Row" name="pb24">
              <Label type="H3" text="Branded payment page" name="splited" />
            </div>
            <div className="App-Row">
              <Label type="P" text="The unique design and style of your resource and payment service increases the conversion and customer loyalty. Customer confidence in the payment process ensures the development of your business." />
            </div>
            <div className="App-Row" name="pb24">
              <Label type="H3" text="Adaptability" name="splited" />
            </div>
            <div className="App-Row">
              <Label type="P" text="The payment page will display correctly and correctly in all browsers and platforms, so users will find it convenient to make a purchase in most situations and on most devices." />
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderSettingsSections() {
    return(
      <div className="App-Row pb90">
        <div className="App-Col rp8">
          <div className="App-Row" name="pb24">
            <Label type="H3" text="You can configure everything" name="splited" />
          </div>
          <div className="App-Row">
            <Label type="P" text="You can customize the color, background, design of the fields and buttons in just a couple of clicks." />
          </div>
          <div className="App-Row">
            <Label type="P" text="Thanks to the component approach, Finkit gives complete freedom of action to customize the payment page." />
          </div>
        </div>
        <div className="App-Col lp8" name="last">
          <Image type="All-Settings"/>
        </div>
      </div>
    )
  }

  renderStartSection() {
    return (
      <div className="App-Row p64-80 br60 BG-pw">
        <div className="App-Row pb24">
          <Label type="H2" text="Ready to get started?" />
        </div>
        <div className="App-Row Manager-Question">
          <div className="App-Col">
            <Label type="P" text="Fill out the form and our managers will contact you to increase the individual offer for your business."/>
          </div>
          <div className="App-Col Manager-Question">
            <div className="App-Col Manager">
              <Button type="Navigation-Button-Page-Invert" text="Managers" action="state" value={{"page":"managers"}} />
            </div>
            <div className="App-Col Connect">
              <Button type="Navigation-Button-Page" text="Connect" action="state" value={{"page":"connect"}} />           
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() { 
    return(
      <div className="App-Body-Content">
        {this.renderPaymentMethodSections()}
        {this.renderPersonalAccountSections()}
        {this.renderNumbersSections()}
        {this.renderButtonSections()}
        {this.renderCustomPageSections()}
        {this.renderSettingsSections()}
        {this.renderStartSection()}
      </div>
    )
  }
}

export default Opportunities;