import React from 'react';

import { connect }  from 'react-redux';

import { AppStore } from '../../App';

import Translate  from '../../Utils/Transalte';

import './Button.css';

class Button extends React.Component{

  constructor( props ){
    super(props);
    var state      = this._initState( this.props );
        this.state = state;
  }

  componentDidUpdate(){

  }
  
  _initState = ( props ) => {

    var state = {
      "name"  : "",
      "type"  : "",
      "text"  : "",
      "value" : "",
      "style" : "",
      "action": "",
    };

    if( props !== undefined ){
      for( var key in props ){
        state[key] = props[key];
      }
    }
    return state;
  }

  onSubmit = () => {
  }

  onClick = () => {
    switch(this.state.action){
      case "state": 
      
        if(this.state.type !== "App-Lang-Btn")
          setTimeout(() => { window.scrollTo(0, 0); }, 10);
        AppStore.dispatch({
          "type": "UPDATE_STATE",
          "data": this.props.value
        });
        break;
      case "submit": 
        this.onSubmit();
        break;
      case "redirect": 
        document.location.href = this.props.page_data.redirect_url;
        break;
      case "redirectPage":
        const newWindow = window.open(this.props.page_data.redirect_url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
      break;
      default: 
        break;
    }
  }

  render(){
    if( (this.state.text !== "" && this.state.text !== undefined) || (this.state.type === "Header-Logo-Finkit") || (this.state.type === "Footer-Logo-Finkit") ){
      return(
        <div name      = {this.props.name} className = {"Button " + this.state.style + " " + this.state.type } onClick = { this.onClick }>
        <div className = {"Button-Text " + this.state.style + " "  + this.state.type }>{ Translate( this.state.text, this.props.language ) }</div>
        </div>
      )
    }else{
      return ""
    }
  }

}

const mapStateToProps = (state) => {
  return{
    language: state.page_state.data.language,
  }
}

export default connect(mapStateToProps)(Button);


