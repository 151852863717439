import React from 'react';

import { connect }  from 'react-redux';

import Translate  from '../../Utils/Transalte';

import './Label.css';

class Label extends React.Component{

  constructor( props ){
    super(props);
    var state      = this._initState( this.props );
    this.state = state;
  }
  
  componentDidUpdate(){

  }

  _initState = ( props ) => {

    var state = {
      "name"      : "",
      "type"      : "",
      "text"      : "",
      "style"     : "",
      "title"     : "",
      "textField" : "",
      "children"  : []
    };

    if( props !== undefined ){
      for( var key in props ){
        state[key] = props[key];
      }
    }
    return state;
  } 

  onClick = () => {
    switch(this.state.textField){
      case "mail": 
        var email = "";
        if(this.state.title){
          email = this.state.title
        } else if(this.state.text) {
          email = this.state.text
        }
        window.location.href = `mailto:${email}`;
        break;
      case "tel": 
        var phoneNumber = "";
        if(this.state.title){
          phoneNumber = this.state.title
        } else if(this.state.text) {
          phoneNumber = this.state.text
        }
        window.location.href = `tel:${phoneNumber}`;
        break;
      default: 
        break;
    }
  }

  render(){
    if( this.state.text !== "" && this.state.text !== undefined ){
      return(
        <div name      = {this.props.name} className                                      = { "Label " + this.state.style + " " + this.state.type } onClick={this.onClick}>
        <div className = {"Label-Title " + this.state.style + " " + this.state.type} name = {this.props.name} >{ Translate( this.state.title, this.props.language ) }</div>
        <div className = {"Label-Text " + this.state.style + " " + this.state.type } name = {this.props.name} >{ Translate( this.state.text, this.props.language ) }</div>
        </div>
      )
    }
  }

}

const mapStateToProps = (state) => {
  return{
    language: state.page_state.data.language,
  }
}

export default connect(mapStateToProps)(Label);

