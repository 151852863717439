import React from 'react';

import Label  from '../Components/Label/Label';
import Image  from '../Components/Image/Image';
import FileButton from '../Components/FileButton/FileButton';

class OpenInfo extends React.Component{
  render() { 
    return(
      <div className="App-Body-Content">
        <div className="App-Row pb160">
          <div className="App-Col rp8">
            <div className="App-Row" name="pb24">
              <Label type="H1" text="Disclosure of information" name="splited" action="state"/>
            </div>
            <div className="App-Row">
              <Label type="P" text="Our company is committed to transparency and openness in communication with all stakeholders." />
            </div>
            <div className="App-Row">
              <Label type="P" text="This section provides an opportunity to thoroughly acquaint oneself with the information reflecting the company's activities." />
            </div>
          </div>
          <div className="App-Col lp8" name="last">
            <div className="Shiny-Dots">
              <div className="Dot Big l10 t10 P"/>
              <div className="Dot b100 r-10 b10 BB"/>
            </div>
            <Image type="OpenInfo"/>
          </div>
        </div>

        <div className="App-Row pb80">

          <div className="App-Row">
            <div className="App-Col rp20">
              <FileButton 
                text  = "View"
                title = "Публічний договір про надання платіжних послуг"
                link  = "https://drive.google.com/file/d/1mXkiOPfT0yoKN-rU3YDjjdmuZbGmgOGg/view?usp=share_link"
              />
            </div>
            <div className="App-Col rp20">
              <FileButton
                text  = "View"
                title = "Інформація про реєстрацію та державне регулювання"
                link  = "https://drive.google.com/file/d/1utWzjGHgdh1aMmTNQ0xuISxpKWCqosvh/view?usp=share_link"
              />
            </div>
          </div>

          <div className="App-Row">
            <div className="App-Col rp20">
              <FileButton
                text  = "View"
                title = "Внутрішні правила про переказ коштів без відкриття рахунків"
                link  = "https://drive.google.com/file/d/1motz8NSkFx_J1JGuTqMv32nsyZWSuJnx/view?usp=share_link"
              />
            </div>
            <div className="App-Col rp20">
              <FileButton
                text  = "View"
                title = "Інформація про механізм захисту прав споживачів"
                link  = "https://drive.google.com/file/d/1wDaw-5lSucieYzH0Wl-srfJ0tZL1a-Pe/view?usp=share_link"
              />
            </div>
          </div>

          <div className="App-Row">
            <div className="App-Col rp20">
              <FileButton
                text  = "View"
                title = "Порядок взаємодії зі споживачами та порядок розгляду звернень споживачів"
                link  = "https://drive.google.com/file/d/1-kTyUEM9LGwxOqC8eqnpIfSzENDQ3zku/view?usp=share_link"
              />
            </div>
            <div className="App-Col rp20">
              <FileButton
                text  = "View"
                title = "Участь у платіжних системах"
                link  = "https://drive.google.com/file/d/1s26vztrlOF-sTsCBnbOZzuG-qJmzmnEr/view?usp=share_link"
              />
            </div>
          </div>

          <div className="App-Row">
            <div className="App-Col rp20">
              <FileButton
                text  = "View"
                title = "Перелік послуг, порядок та умови їх надання"
                link  = "https://drive.google.com/file/d/1sSYCxw4erSD9IRG1nvBE9lVtlQo5s3hJ/view?usp=share_link"
              />
            </div>
            <div className="App-Col rp20">
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default OpenInfo;