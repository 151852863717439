
import React from 'react'
import Label  from '../Components/Label/Label';

function Numbers() {

  return (
    <div className="App-Cont">
        <div className="Cont-1">
            <div className="BigNum mr22">
                1
            </div>
            <div className="NText">
                <Label type="NTitle" text="Registration"/>
                <Label type="NPtext pad16" text="To get started, you need to register and access your personal account."/>
                <Label type="NPtext  pad16" text="To authenticate records in the API, you need a secret key from the personal account."/>
            </div>
        </div>

        <div className="Cont-2">
            <div className="BigNum mr12">
                2
            </div>
            <div className="NText">
                <Label type="NTitle" text="Insert code"/>
                <Label type="NPtext pad16" text="Copy the code to receive the payment, replacing it on the test page with a secret key from the personal account."/>
                <Label type="NPtext pad16" text="Paste the code on your site."/>
            </div>
        </div>

        <div className="Cont-3">
            <div className="BigNum mr12">
                3
            </div>
            <div className="NText">
                <Label type="NTitle" text="Done!"/>
                <Label type="NPtext pad16" text="Hooray! You can accept online payments."/>
                <Label type="NPtext pad16" text="All payment data, statuses and detailed information will be available in the personal account."/>
            </div>
        </div>
    </div>
  );
}

export default Numbers;