import React from 'react';

import { connect }  from 'react-redux';

import Button from '../Button/Button';

import './Burger.css';

class Burger extends React.Component{

  Show = ( e ) => {
    e.target.parentNode.childNodes[1].classList.add("visible");
    e.target.parentNode.childNodes[2].classList.add("visible");
  }

  Hide = ( e ) => {
    e.target.parentNode.parentNode.classList.remove("visible");
    e.target.parentNode.parentNode.parentNode.childNodes[2].classList.remove("visible");
  }

  Hide2 = ( e ) => {
    e.target.parentNode.childNodes[1].classList.remove("visible");
    e.target.parentNode.childNodes[2].classList.remove("visible");
  }
  
  Hide3 = ( e ) => {
    e.target.parentNode.parentNode.parentNode.parentNode.childNodes[1].classList.remove("visible");
    e.target.parentNode.parentNode.parentNode.parentNode.childNodes[2].classList.remove("visible");
  }

  Hide4 = ( e ) => {
    e.target.parentNode.parentNode.parentNode.parentNode.parentNode.childNodes[1].classList.remove("visible");
    e.target.parentNode.parentNode.parentNode.parentNode.parentNode.childNodes[2].classList.remove("visible");
  }


  render() { 
    return(
      <div className="Burger">
        <div className="Burger-Icon Show" onClick={this.Show}></div>
        <div className="Burger-Items-Wrapper" >
          <div className="Burger-Content-Header" >
            <div className="Burger-Icon Close" onClick={this.Hide}></div>
              <div onClick={this.Hide4}>
                <Button type="Header-Logo-Finkit" />
                {/* .Button.Header-Logo-Finkit */}
              </div>
          </div>
          <div className="Burger-Content-Row" onClick={this.Hide3}>
            <Button text="Opportunities" type="Burger-Header-Navigation-Link" action="state" value={{"page":"opprtunities"}} ></Button>
          </div>
          <div className="Burger-Content-Row" onClick={this.Hide3}>
            <Button text="Developers" type="Burger-Header-Navigation-Link" action="state" value={{"page":"developers"}} ></Button>
          </div>
          <div className="Burger-Content-Row" onClick={this.Hide3}>
            <Button text="About the company" type="Burger-Header-Navigation-Link" action="state" value={{"page":"about"}} ></Button>
          </div>
          <div className="Burger-Content-Row" onClick={this.Hide3}>
            <Button text="Disclosure of information" type="Burger-Header-Navigation-Link" action="state" value={{"page":"openinfo"}} ></Button>
          </div>
          <div className="Burger-Content-Row">
            <div className="Burger-Delimiter"></div>
          </div>
          <div className="Burger-Content-Row" onClick={this.Hide3} >
            <Button text="Connect" type="Header-Navigation-Button" action="state" value={{"page":"connect"}}></Button>
          </div>
        </div>
        <div className="Burger-blur" onClick={this.Hide2}></div>
      </div>
    )
  }

}



const mapStateToProps = (state) => {
  return{
    language:       state.page_state.data.language,
  }
}

export default connect(mapStateToProps)(Burger);


