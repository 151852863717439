import React from 'react';

import { connect }  from 'react-redux';
import './SupportButton.css';
import '../../styles/typography.css'
import Translate  from '../../Utils/Transalte';

class SupportButton extends React.Component {

  render() {
    return (
      <div className='support-button__container' onClick={this.onClick}>
        <div className='support-button__telegram-on-icon'></div>
        <div className='support-button__text-container'>
          <div id='support-button__title-id' className='text-body-bold-normal'>{Translate("Need help?", this.props.language)}</div>
          <div id='support-button__subtitle-id' className='text-body-regular-small'>{Translate("We are always here", this.props.language)}</div>
        </div>
      </div>
    )
  }

  onClick = () => {
    if (this.props.link !== undefined) {
      const newWindow = window.open(this.props.link, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }
  }
}

const mapStateToProps = (state) => {
  return{
    language: state.page_state.data.language,
  }
}

export default connect(mapStateToProps)(SupportButton);
