import React from 'react';

import Footer from '../Footer/Footer';

import { connect }  from 'react-redux';

import './Body.css';

import './Body-1440.css';
import './Body-1300.css';
import './Body-1242.css';
import './Body-1200.css';
import './Body-1140.css';
import './Body-1024.css';
import './Body-978.css';
import './Body-900.css';
import './Body-846.css';
import './Body-768.css';
import './Body-705.css';
import './Body-640.css';
import './Body-576.css';
import './Body-480.css';
import './Body-430.css';
import './Body-375.css';

import Home from '../../Pages/Home';
import About from '../../Pages/About';
import Connect from '../../Pages/Connect';
import Developers from '../../Pages/Developers';
import Opportunities from '../../Pages/Opportunities';
import Managers from '../../Pages/Managers';
import OpenInfo from '../../Pages/OpenInfo';

var pages = {
  "home"        : Home,
  "about"       : About,
  "connect"     : Connect,
  "developers"  : Developers,
  "opprtunities": Opportunities,
  "managers"    : Managers,
  "openinfo"    : OpenInfo
}

class Body extends React.Component{

  render() { 
    var page      = {};
        page.item = class Empty extends React.Component { render(){ return( "" ) } };
    if( pages[this.props.page] !== undefined )
      page.item = pages[this.props.page];
    return(
        <div className = "App-Body">
          <page.item/>
          <Footer/>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return{
    page: state.page_state.data.page,
  }
}

export default connect(mapStateToProps)(Body);
