import React from 'react';
import Label from '../Components/Label/Label';
import Image from '../Components/Image/Image';
import Button from '../Components/Button/Button';

class Developers extends React.Component {
  render() {
    
    const introTexts = [
      "Allows you to accept payments, make returns, find out the status of transactions and much more.",
      "The documentation contains descriptions of the main scenarios with code examples. It only takes a few clicks and lines of code to accept a payment."
    ];

    return (
      <div className="App-Body-Content">
        <div className="App-Row Devs pb90">
          <div className="App-Col rp8">
            <div className="App-Row" name="pb24">
              <Label type="H1" text="Finkit API" name="splited" />
            </div>
            {introTexts.map((text, index) => (
              <div key={index} className="App-Row">
                <Label type="P" text={text} />
              </div>
            ))}
            <div className="App-Row">
              <Button 
                text="Documentation" 
                type="Header-Navigation-Button" 
                action="redirectPage" 
                page_data={{redirect_url: "https://docs.google.com/document/d/1vJRyFaflqTt4MLVaXWCNwU1gnpegFBNIraOB6j-_gKU/edit?usp=sharing"}}
              />
            </div>
          </div>
          <div className="App-Col lp8" name="last">
            <Image type="Devs"/>
          </div>
        </div>
      </div>
    );
  }
}

export default Developers;
