function initState(){  

  // var userLang = navigator.language || navigator.userLanguage;
  // var browserLang = userLang.toLowerCase().slice(0, 2);
  // console.log("browser lan = ", browserLang);



  return {
    "data":{
      "page"    : "home",
      "language": ["uk"]
    }
  }
}   

export default function PageReducer( state = initState(), action ){
  switch (action.type){
    case "UPDATE_STATE": 
      var data = state.data;
      for( var k in action.data ){
        if( data[k] !== undefined ){
          data[k] = action.data[k];
        }
      }
      data.s = "";
      return {
        ...state,data: data
      }
    default: 
      return state;
  }
}