import React, { useState, useEffect } from 'react';
import axios from 'axios';                  
import classnames from 'classnames';        
import InputMask from "react-input-mask"; 
import Translate  from '../../Utils/Transalte';


function ManagerForm(propsData) {
  const language = propsData.language;
//поля
  const [firstName, setfirstName] = useState('');
  const [email, setemail] = useState('');
  const [phone, setphone] = useState('');
  
//ошибки
  const [firstNameErr, setfirstNameErr] = useState('');
  const [emailErr, setemailErr] = useState('');
  const [phoneErr, setphoneErr] = useState('');

//отправка формы
  var submitURL = process.env.REACT_APP_SUBMIT_URL;

  const initialFormState = {
    firstName: '',
    phone: '',
    email: '',
    description: ''
  };

  const [formState, setFormState] = useState(initialFormState);
  const [phoneFocused, setPhoneFocused] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [description, setdescription] = useState();

  useEffect(() => {
    const isValid = !phoneFocused && firstName && email && !firstNameErr && !emailErr && !phoneErr;
    setCanSubmit(isValid);
  }, [firstName, phoneFocused, email, phone, firstNameErr, emailErr, phoneErr]);


  const submitForm = async (event) => {
    event.preventDefault();
    if(canSubmit && !phoneFocused){
      setSubmitting(true);
      await postSubmission();
      setSubmitting(false);
    }
  };

//обработчик ответов апи     
  const postSubmission = async() => {

    const data = {
      ...formState,
    };

    const payload = {
      support_type: 'question',
      support_channel: 'landing',
      support_information: data,
      support_status: 'created',
      support_send_mail: process.env.REACT_APP_SUPPORT_SEND_MAIL
    }

    try {
      const result = await axios.post(submitURL, payload);
      console.log(result);
      setdescription({
        text: Translate("Thank you, the form has been sent, we will contact you soon.", language),
      });
    } catch(error) {
        console.log(error);
        setdescription({
        text: Translate("Sorry, something went wrong. Please try again later or contact online support.", language),
      });
    } 
  };

  const updateFormControl = (event, name) => {
    const { id, value } = event.target;
    const formKey = id;
    const updateFormState = {...formState};
    updateFormState[formKey] = value;
    setFormState(updateFormState);
      
    const user = {};

//регулярка почты
    const emailRegEx = RegExp(              	
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
//регулярка телефона
    const phoneRegEx = RegExp(
      /((\+380)?\(?\d{2}\)?[\s.-]?(\d{7}|\d{3}[\s.-]\d{2}[\s.-]\d{2}|\d{3}-\d{4}))/
    );

    user[name] = event.currentTarget.value;

//валидация и тексты ошибок
    switch (name) {
      case 'firstName': 
        setfirstName(user.firstName);
        user.firstName.length < 3 ? setfirstNameErr("Required field. Minimum 3 characters") : setfirstNameErr('');
        break;
      case 'email': 
        setemail(user.email);
        !emailRegEx.test(user.email) ? setemailErr("The format of the email address is incorrect") : setemailErr('');
        break;
      case 'phone': 
        setphone(user.phone);
        (!phoneRegEx.test(user.phone) && user.phone !== '') ? setphoneErr("Enter the phone number in the format: +380 (##) ###-##-##") : setphoneErr('');
        break;
      default:
        break;
    }

  };

  //обработка значений value для корректного отображения в запросе POST (Axios)
  const Replaces = (event,name) => {
    const id = 'email';
    const id2 = 'phone';
    const id3 = 'firstName';
    const formKey = id;
    const formKey2 = id2;
    const formKey3 = id3;
    const phoneInputState = {...formState}
    phoneInputState[formKey] = email.toLowerCase().replace(/[^a-z\s,.,@]/gi, '');
    phoneInputState[formKey2] = phone.replace(/[^\d]/g, '');
    phoneInputState[formKey3] = firstName.replace(/[0-9]/g, '');
    setFormState(phoneInputState);
  }

return (
  <div className="Forms">
      <form 
        onSubmit={submitForm} 
        >
        <div className="Form-Div">
          <input 
              className={classnames(
                  "form-control",
                  { 'is-invalid': firstNameErr, 'is-valid': !firstNameErr && firstName.length}
              )}
              onChange={(event) => updateFormControl(event, 'firstName')}
              type="text"
              name="firstName" 
              id="firstName" 
              value={formState.firstName.replace(/[0-9]/g, '')} 
              placeholder={Translate("First name" , language)}
              noValidate
              required
                />
              <div className="error_field">
                  {firstNameErr &&<small className="text-danger">{Translate(firstNameErr, language)}</small>}
              </div>                 
      </div>
      <div className="Form-Div">
          <input
              className={classnames(
                  "form-control",
                  { 'is-invalid': emailErr, 'is-valid': !emailErr && email.length}
              )} 
              onChange={(event) => updateFormControl(event, 'email')} 
              type="text" 
              id="email"
              name="email"  
              value={formState.email.toLowerCase().replace(/[^a-z\s,.,@]/gi, '')}
              placeholder="E-mail"
              noValidate
                />
          <div className="error_field">
              {emailErr && <small className="text-danger">{emailErr}</small>}
          </div>
      </div>
      <div className="Form-Div">
          <InputMask
                  className={classnames(
                      "form-control",
                      { 'is-invalid': phoneErr, 'is-valid': !phoneErr && phone.length}
                  )}
                  placeholder={Translate("Mobile phone" , language)}
                  mask="+380 (99) 999-99-99"
                  
                  onChange={(event) => updateFormControl(event, 'phone')} 
                  onFocus={() => setPhoneFocused(true)}
                  onBlur={() => setTimeout(() => {
                    setPhoneFocused(false);
                  }, 200)}
                  
                  type="tel" 
                  id="phone" 
                  name="phone"
                  inputMode="numeric"
                  value={formState.phone}
                  noValidate
              />
          <div className="error_field">
              {phoneErr && <small className="text-danger">{Translate(phoneErr, language)}</small>}
          </div>             
      </div>
      <div>
          <textarea
              className="form-control textarea"
              height="119px" 
              onChange={updateFormControl}
              type="text" 
              id="description"
              name="description" 
              value={formState.description}
              onClick={Replaces}
              placeholder={Translate("Tell us more about your project and needs", language)}>
          </textarea>
        </div>
      {description && (
          <div className="FormSend">
              <div className="TextSend">
                  {description.text}
              </div>
          </div>
      )}
      <div onClick={Replaces}>
        <button 
          className={"btn btn_primary request " + (firstName && email && firstNameErr === '' && emailErr === '' && phoneErr === '' ? "active" : "disabled")}  
          disabled={!canSubmit} 
          t={submitting}>
          {Translate("Send request", language)}
        </button>
      </div>
      </form>
  </div>
);
}

export default ManagerForm;
